//import logo from './logo.svg';
import './App.css';
//import React from 'react';
import { ImageList,ImageListItem } from '@mui/material';
//import { Button } from '@mui/material';
import axios from 'axios';
import Uploader from './Uploader.js'
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Face2TwoTone from '@mui/icons-material/Face2TwoTone';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRef } from 'react';
import { config } from './Constants';
import { useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


class ImageBatch extends React.Component {
  render() { 
    let itemData = this.props.images;
    return (
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
  {itemData.map((item) => (
    <ImageListItem key={item.src}>
      <img
        src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
        srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
        alt={item.title}
        loading="lazy"
      />
    </ImageListItem>
  ))}
</ImageList>
  );}   
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://facejam.io/">
        FaceJam.IO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function App() {

  const [activeJobId, setJobId] = React.useState(null);
  const [resultImage, setResultImage] = React.useState(null);

  var uploadedFiles = useRef([]);

  const getactiveJobId = () => {
    return activeJobId;
  }
 
  useEffect(() => {
    const interval = setInterval(() => {
      console.log(activeJobId);
      if (activeJobId){
        var result_image = config.url.BUCKET_DESTINATION + activeJobId + '/average.jpg';
        setResultImage(result_image);
      }
    }, 2000);
  
    return () => clearInterval(interval);
  }, [activeJobId]);

  function setWaiting(e) {
    setResultImage('images/waiting.gif');
  }

  const getSignedUrl = async (job) => {
    console.log(config.url.API_SIGNED_UPLOAD_URL)
    return await axios.get(config.url.API_SIGNED_UPLOAD_URL, {
      params: {'job': job}
    });
  }

  const uploadImage = async (url, fields, image) => {
    console.log(url)
    console.log(fields)
    console.log(image)
    var formData = new FormData();
    
    for (const k in fields) {
      formData.set(k, fields[k]);
    }
    
    console.log(image)
    
    formData.append("file", image, image.name);
      axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
    })
  }

  const uploadImages = async (job, images) => {
    const asyncMethod = async (val) => { 
      // get_signed_post_url
      const res = await getSignedUrl(job)
      const res2 = await uploadImage(res.data.url, res.data.fields, val);
      return await res2; 
      //const res = await axios.get('https://catfact.ninja/fact');
      // const fourth_word = res.data.fact.split(" ")[4]
      // const res2 = await axios.get('https://api.genderize.io?name=' + fourth_word); 
      // console.log(res2)
      
    };
    return await Promise.all(images.map(asyncMethod))
  }

  const startJob = async (job) => {
    axios.post(config.url.API_START_JOB_URL, {'job': job}).then(
      (res) => {
        setJobId(res.data['job_id']);
        setWaiting();
      }
    )
  }

  const randomString = () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    return r
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    //uploadImages("goo@bar.com", "12335", )
    
    const data = new FormData(event.currentTarget);
    
    const job = randomString()
    const images = uploadedFiles.current;

    uploadImages(job, images).then(() => startJob(job));

    // Lock the form (3)
    // Move the images to the left in a tiny grid (2)
    // request URLs + upload one at a time. (1)
    // Show the uploaded in a separate grid (2)
    // Kick off the averaging job (1)
    // Upon receipt
    
    // put the images on the left side
    // remove the 
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} maxWidth="1200px">
      <Grid item xs={6}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Face2TwoTone />
          </Avatar>
          <Typography component="h1" variant="h5">
            Make a Face Mash up!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {/* <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            /> */}
{/*             
            <TextField
              margin="normal"
              required
              fullWidth
              name="job"
              label="Choose a name for this Mashup"
              id="job"
            /> */}
            {/* <ImageBatch images={IMAGES}></ImageBatch> */}
            <Uploader fileRef={uploadedFiles}></Uploader>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2 }}
            >
              Generate Average Face!
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  What is this?
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://twitter.com/JacobSingh" variant="body2">
                  {"A project by @JacobSingh"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </Grid>
      <Grid item xs={6}>
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <img style={{width: '400px', verticalAlign: 'bottom'}} id="result" src={resultImage} onError={setWaiting}/>
      </Box>
        
      </Grid>
      </Grid>
    </ThemeProvider>
  );
}

//export default App;
