// Constants.js
const prod = {
    url: {
     API_SIGNED_UPLOAD_URL: 'https://3rlhhmdm51.execute-api.ap-south-1.amazonaws.com/beta/signed_url',
     API_START_JOB_URL: 'https://3rlhhmdm51.execute-api.ap-south-1.amazonaws.com/beta/job/start',
     BUCKET_DESTINATION: 'https://fa-dstbucket-p6if0viyzhv5.s3.ap-south-1.amazonaws.com/'
        }
   };
   const dev = {
    url: {
     API_SIGNED_UPLOAD_URL: 'https://3rlhhmdm51.execute-api.ap-south-1.amazonaws.com/beta/signed_url',
     API_START_JOB_URL: 'https://3rlhhmdm51.execute-api.ap-south-1.amazonaws.com/beta/job/start',
     BUCKET_DESTINATION: 'https://fa-dstbucket-p6if0viyzhv5.s3.ap-south-1.amazonaws.com/'
    }
   };
export const config = process.env.NODE_ENV === 'development' ? dev : prod;